import { FC } from "react";
import {
  Tag,
  TagLabel,
  Text,
  Flex,
  FlexProps,
  TextProps,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { getTimeFromNowConditional } from "@utils/time";
import { Link } from "react-router-dom";
import { Version } from "@xboxstudios/hermes-apis/xboxstudios/hermes/product/v1/product_registry_pb";
import { VersionIcon } from "./VersionIcon";

interface VProp {
  v: Version;
}

export const VersionTags: FC<VProp & FlexProps> = ({ v, ...rest }) => {
  const obj = v.toObject();
  return (
    <Flex wrap="wrap" {...rest}>
      {obj.propertiesMap.map((p) => (
        <Tag key={p[0]} size="sm" fontSize="x-small" mr={2} my={1}>
          <TagLabel mr={1}>{p[0]}</TagLabel>
          <Text fontWeight="normal">{p[1]}</Text>
        </Tag>
      ))}
    </Flex>
  );
};

export const VersionReleaseDate: FC<VProp & TextProps> = ({ v, ...rest }) => {
  const time = v.getCreateTime()?.getSeconds() || 0;
  const createDate = getTimeFromNowConditional(
    time,
    { days: 7 },
    { dateFormat: "MMM dd yyy" }
  );
  return <Text {...rest}>{createDate}</Text>;
};

export const getVersionBuildId = (v: Version) => {
  return (
    v.getPropertiesMap().get("build.id") ??
    v.getPropertiesMap().get("build_id") ??
    ""
  );
};

export interface VersionDisplayNameProps {
  linkActive?: boolean;
  showIcon?: boolean;
}

export const VersionDisplayName: FC<
  VProp & VersionDisplayNameProps & FlexProps
> = ({ v, linkActive, showIcon = true, ...rest }) => {
  const displayName = v.getBuildId() || v.getId();
  return (
    <Flex
      display="inline-flex"
      align="center"
      title={displayName}
      {...rest}
      maxW="full"
    >
      {showIcon && <VersionIcon />}
      {linkActive ? (
        <ChakraLink as={Link} to={`/${v.getName()}`} isTruncated>
          {displayName}
        </ChakraLink>
      ) : (
        displayName
      )}
    </Flex>
  );
};
