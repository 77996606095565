import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Input: ComponentMultiStyleConfig = {
  variants: {
    filled: (props) => ({
      field: {
        bg: mode("whiteAlpha.700", "whiteAlpha.50")(props),
        _hover: {
          bg: mode("whiteAlpha.800", "whiteAlpha.100")(props),
        },
        _focusVisible: {
          bg: mode("whiteAlpha.800", "whiteAlpha.100")(props),
        },
        _focusWithin: {
          bg: mode("whiteAlpha.800", "whiteAlpha.100")(props),
        },
      },
    }),
  },
  parts: ["field", "addon"],
};

export default Input;
