import { mode } from "@chakra-ui/theme-tools";

// @ts-ignore
const hoverable = (props) => {
  return {
    borderColor: "gray.200",
    borderRadius: 4,
    _hover: { boxShadow: "md", bg: mode("gray.200", "gray.600") },
    _focusWithin: { boxShadow: "outline" },
  };
};

// @ts-ignore
const selectable = (props) => {
  return {
    ...hoverable(props),
    bg: mode("gray.100", "gray.800"),
    cursor: "pointer",
  };
};

const Box = {
  variants: {
    hoverable,
    selectable,
  },
};

export default Box;
