/**
 * Helper functions for dealing with hermes resource names
 */

import { Operation } from "@xboxstudios/hermes-apis/xboxstudios/hermes/operations/v1/operations_pb";
import { getOperationMetadata } from "./operation";
import { ResourceType } from "./types";

/**
 * Returns a portion of a URL string separated by forward slashes ("/").
 * @param url URL string to segment.
 * @param count Number of segments to extract. If greater than the number of segments, returns the most segments available.
 * @param startIndex Index of the segment to start extracting. If greater than the segments available returns an emptry string. Defaults to 0.
 * @returns A segmented URL string
 *
 * ```
 * // ex:
 *  segmentResourceName("one/two/three/four", 2) // "one/two"
 *  segmentResourceName("one/two/three/four", 2, 1) // "two/three"
 * ```
 */
export const segmentResourceName = (
  url: string,
  count: number,
  startIndex = 0
) => {
  return url.split("/").splice(startIndex, count).join("/");
};

/**
 * Returns the organization name from a Product, Channel, Version, Depot, or Archive name, or from a URL string starting with an org name.
 * @param resourceName String to extract the organization name from.
 * @returns An organization name
 * ```
 * // ex:
 * getOrgNameFromResource("orgs/<org-id>/products/<product-id>") // "orgs/<org-id>"
 * ```
 */
export function getOrgNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 2);
}

/**
 * Returns the product name from a Channel or Version name, or from a URL string starting with a product name.
 * @param resourceName String to extract the product name from.
 * @returns A product name
 * ```
 * // ex:
 * getProductNameFromResource("orgs/<org-id>/products/<product-id>/channels/<channel-id>") // "orgs/<org-id>/products/<product-id>"
 * ```
 */
export function getProductNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 4);
}

/**
 * Returns the channel name from a URL string starting with a channel name.
 * @param resourceName String to extract the channel name from.
 * @returns An channel name
 * ```
 * // ex:
 * getArchiveNameFromResource("orgs/<org-id>/products/<product-id>/channels/<channel-id>/<url-string>") // "orgs/<org-id>/products/<product-id>/channels/<channel-id>"
 * ```
 */
export function getChannelNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 6);
}

/**
 * Returns the version name from a URL string starting with a version name.
 * @param resourceName String to extract the version name from.
 * @returns An version name
 * ```
 * // ex:
 * getArchiveNameFromResource("orgs/<org-id>/products/<product-id>/versions/<version-id>/<url-string>") // "orgs/<org-id>/products/<product-id>/versions/<version-id>"
 * ```
 */
export function getVersionNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 6);
}

/**
 * Returns the depot name from an Archive name, or from a URL string starting with a depot name.
 * @param resourceName String to extract the depot name from.
 * @returns A depot name
 * ```
 * // ex:
 * getDepotNameFromResource("orgs/<org-id>/depost/<depot-id>/archives/<archive-id>") // "orgs/<org-id>/depots/<depot-id>"
 * ```
 */
export function getDepotNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 4);
}

/**
 * Returns the archive name a URL string starting with an archive name.
 * @param resourceName String to extract the archive name from.
 * @returns An archive name
 * ```
 * // ex:
 * getArchiveNameFromResource("orgs/<org-id>/depots/<depot-id>/archive/<archive-id>/<url-string>") // "orgs/<org-id>/depots/<depot-id>/archives/<archive-id>"
 * ```
 */
export function getArchiveNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 6);
}

/**
 * Returns the device name from an Operation name, or from a URL string starting with a device name.
 * @param resourceName String to extract the device name from.
 * @returns A device name
 * ```
 * // ex:
 * getDeviceNameFromResource("devices/<device-id>/operations/<operation-id>") // "devices/<device-id>"
 * ```
 */
export function getDeviceNameFromResource(resourceName: string) {
  return segmentResourceName(resourceName, 2);
}

/**
 * Extracts the the associated product, channel, and/or version names for an operation.
 * > _Note: Channel or Version name will only be present if the install
 * is for that specific resource._
 */
export const getInstallNamesFromOperation = (operation: Operation) => {
  const opMetadata = getOperationMetadata(operation);
  const cmd = opMetadata?.getCommand();
  const psc = cmd?.getProductStateCommand();

  let productName: string = "";
  let channelName: string = "";
  let versionName: string = "";

  if (psc) {
    productName = psc.getProduct();
    channelName = psc.getChannel();
    versionName = psc.getVersion();
  }

  if (versionName) {
    productName = getProductNameFromResource(versionName);
  } else if (channelName) {
    productName = getProductNameFromResource(channelName);
  }

  return {
    productName,
    channelName,
    versionName,
  };
};

export const getResourceTypeFromName = (resourceName: string): ResourceType => {
  const nameParts = resourceName.split("/");
  if (
    nameParts.length === 6 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "depots" &&
    nameParts[4] === "archives"
  ) {
    return "archive";
  }
  if (
    nameParts.length === 6 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "products" &&
    nameParts[4] === "versions"
  ) {
    return "version";
  }
  if (
    nameParts.length === 6 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "products" &&
    nameParts[4] === "channels"
  ) {
    return "channel";
  }
  if (
    nameParts.length === 4 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "products"
  ) {
    return "product";
  }
  if (
    nameParts.length === 4 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "depots"
  ) {
    return "depot";
  }
  if (nameParts.length === 2 && nameParts[0] === "orgs") {
    return "org";
  }
  if (nameParts.length === 2 && nameParts[0] === "devices") {
    return "device";
  }
  if (
    nameParts.length === 2 &&
    nameParts[0] === "devices" &&
    nameParts[2] === "operations"
  ) {
    return "operation";
  }
  console.warn(
    `Could not determine resource type from name ${nameParts.join("/")}`
  );
  throw new Error(`Could not parse resource type from name ${resourceName}`);
};

export const getResourceHierarchyFromName = (resourceName: string) => {
  const hierarchy: string[] = [];
  const nameSplit = resourceName.split("/");

  let parentName = nameSplit.slice(0, -2);

  while (parentName.length > 1) {
    getResourceTypeFromName(parentName.join("/"));
    hierarchy.push(parentName.join("/"));
    parentName = parentName.slice(0, -2);
  }

  return hierarchy;
};
