import { getAccessToken } from "@auth/auth";
import { FieldMask } from "@xboxstudios/hermes-apis/google/protobuf/field_mask_pb";
import {
  DownloadMediaAssetRequest,
  UploadMediaAssetRequest,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/media/v1/media_pb";
import {
  Channel,
  CreateChannelRequest,
  CreateProductRequest,
  DeleteChannelRequest,
  DeleteProductRequest,
  DeleteVersionRequest,
  GenerateContentTokenRequest,
  GetChannelRequest,
  GetProductRequest,
  GetVersionRequest,
  ListChannelsRequest,
  ListProductsRequest,
  ListVersionsRequest,
  Product,
  UndeleteChannelRequest,
  UndeleteVersionRequest,
  UpdateChannelRequest,
  UpdateProductRequest,
  UpdateVersionRequest,
  Version,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/product/v1/product_registry_pb";
import { HttpBody } from "@xboxstudios/hermes-apis/google/api/httpbody_pb";
import { HermesApiPermissions } from "./api-permissions";
import { getHermesClient } from "./client";
import { ListQueryOptions } from "./types";
import { getHermesEnvironment } from "@env/envrionment";

const getProductRegistryPromiseClient = () => {
  return getHermesClient().productRegistryPromiseClient;
};

const getToken = async (scopes: string[]) => await getAccessToken({ scopes });

const getMetadata = async (
  scopes: string[],
  additionalFields?: { [key: string]: string }
) => {
  const token = await getToken(scopes);
  return {
    Authorization: `Bearer ${token}`,
    ...additionalFields,
  };
};

/**
 * WARNING: This should only be used when the content token is needed for a specific version.
 * Since the content token grants access to the content of the actual build, it should not be used
 * unless absolutely necessary.
 *
 * @param versionName Version name to generate a content token for
 * @returns {Promise<GenerateContentTokenResponse>} The content token for the requested version
 */
export const getContentToken = async (versionName: string) => {
  const client = getProductRegistryPromiseClient();
  // Note: We don't cache this request since the token grants access to the content
  // of the actual build
  const metadata = await getMetadata([HermesApiPermissions.product], {
    "Cache-Control": "no-cache, no-store, max-age=0",
  });
  const req = new GenerateContentTokenRequest();
  req.addResource(versionName);

  return client.generateContentToken(req, metadata);
};

export type ProductImageSize = "headerCapsule" | "smallCapsule" | "thumbnail";

export const getProductImageUri = (
  product: Product,
  size: ProductImageSize
) => {
  const endpoint = getHermesEnvironment().hermesApi.endpoint;
  // Note: Product Etag is added to the media URI to help with cache busting
  // when media for a product is updated.
  return `${endpoint}/v1/${product.getName()}/media/${size}?v=${product.getEtag()}`;
};

export const addProduct = async (
  orgName: string,
  product: Product,
  productId: string
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new CreateProductRequest();
  req.setParent(orgName);
  req.setProduct(product);
  if (productId) {
    req.setProductId(productId);
  }

  return client.createProduct(req, metadata);
};

export const listProducts = async (
  orgName: string,
  options: ListQueryOptions
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new ListProductsRequest();
  req.setParent(orgName);
  req.setShowDeleted(options?.showDeleted ?? false);
  if (options.pageToken) {
    req.setPageToken(options?.pageToken);
  }
  if (options.pageSize) {
    req.setPageSize(options?.pageSize);
  }
  return client.listProducts(req, metadata);
};

export const getProduct = async (productName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new GetProductRequest();
  req.setName(productName);
  return client.getProduct(req, metadata);
};

export const updateProduct = async (
  productEdits: Product,
  updatePaths: string[]
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new UpdateProductRequest();
  const updateMask = new FieldMask();
  if (updatePaths) {
    updateMask.setPathsList(updatePaths);
  }
  req.setProduct(productEdits);
  // @ts-ignore
  req.setUpdateMask(updateMask);
  return client.updateProduct(req, metadata);
};

export type ProductAssetType = "thumbnail" | "headerCapsule" | "smallCapsule";

/**
 * Uploads media for a product.
 * @param media The media file to upload. Use `null` or `undefined` to delete an existing asset.
 * @param assetType
 * @param productName
 * @returns
 */
export const uploadMediaAsset = async (
  media: File | null | undefined,
  assetType: ProductAssetType,
  productName: string
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new UploadMediaAssetRequest();

  const content = new HttpBody();

  if (media) {
    content.setContentType(media.type);
    const fileArrayBuffer = await media.arrayBuffer();
    const fileData = new Uint8Array(fileArrayBuffer);
    content.setData(fileData);
  }

  req.setContent(content);
  req.setResource(productName);
  req.setAsset(assetType);

  return client.uploadMediaAsset(req, metadata);
};

export const downloadMediaAsset = async (
  resource: string,
  asset: ProductAssetType
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);

  const req = new DownloadMediaAssetRequest();
  req.setResource(resource);
  req.setAsset(asset);

  return client.downloadMediaAsset(req, metadata);
};

export const deleteProduct = async (productName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new DeleteProductRequest();
  req.setName(productName);
  return client.deleteProduct(req, metadata);
};

export const undeleteProduct = async (productName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new DeleteProductRequest();
  req.setName(productName);
  return client.undeleteProduct(req, metadata);
};

//add optional param for options to toggle delete/undelete
export const listVersions = async (
  productName: string,
  options: ListQueryOptions = {}
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new ListVersionsRequest();
  req.setParent(productName);
  req.setShowDeleted(options?.showDeleted ?? false);
  if (options.pageSize) {
    req.setPageSize(options?.pageSize);
  } else {
    // Temp: set largest page size until API can return
    // versions in chronological order
    req.setPageSize(1000);
  }
  if (options.pageToken) {
    req.setPageToken(options?.pageToken);
  }
  return client.listVersions(req, metadata);
};

export const getVersion = async (versionName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new GetVersionRequest();
  req.setName(versionName);
  return client.getVersion(req, metadata);
};

export const addChannel = async (
  productName: string,
  channel: Channel,
  channelId: string
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new CreateChannelRequest();
  req.setParent(productName);
  if (channelId) {
    req.setChannelId(channelId);
  }
  req.setChannel(channel);
  return client.createChannel(req, metadata);
};

export const listChannels = async (
  productName: string,
  options: ListQueryOptions
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new ListChannelsRequest();
  req.setParent(productName);
  req.setShowDeleted(options?.showDeleted ?? false);
  if (options.pageSize) {
    req.setPageSize(options?.pageSize);
  }
  if (options.pageToken) {
    req.setPageToken(options?.pageToken);
  }
  return client.listChannels(req, metadata);
};

export const getChannel = async (channelName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new GetChannelRequest();
  req.setName(channelName);
  return client.getChannel(req, metadata);
};

export const deleteVersion = async (versionName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new DeleteVersionRequest();
  req.setName(versionName);
  return client.deleteVersion(req, metadata);
};

export const updateChannel = async (
  channel: Channel,
  updatePaths: string[]
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const fieldMask = new FieldMask();
  if (updatePaths) {
    fieldMask.setPathsList(updatePaths);
  }
  const req = new UpdateChannelRequest();
  req.setChannel(channel);
  // @ts-ignore
  req.setUpdateMask(fieldMask);
  return client.updateChannel(req, metadata);
};

export const deleteChannel = async (channelName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new DeleteChannelRequest();
  req.setName(channelName);
  return client.deleteChannel(req, metadata);
};

export const undeleteChannel = async (channelName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new UndeleteChannelRequest();
  req.setName(channelName);
  return client.undeleteChannel(req, metadata);
};

export const undeleteVersion = async (versionName: string) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const req = new UndeleteVersionRequest();
  req.setName(versionName);
  return client.undeleteVersion(req, metadata);
};

export const updateVersion = async (
  versionEdits: Version,
  updatePaths: string[]
) => {
  const client = getProductRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.product]);
  const reqBody = new UpdateVersionRequest();
  const updateMask = new FieldMask();
  if (updatePaths) {
    updateMask.setPathsList(updatePaths);
  }
  reqBody.setVersion(versionEdits);
  // @ts-ignore
  reqBody.setUpdateMask(updateMask);
  return client.updateVersion(reqBody, metadata);
};
