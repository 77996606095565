import { IPublicClientApplication } from "@azure/msal-browser";

export function getMemberAccountsByTenantId(
  msalInstance: IPublicClientApplication,
  tenantId: string
) {
  return msalInstance
    .getAllAccounts()
    .filter(
      (a) =>
        a.tenantId === tenantId && a.homeAccountId.split(".")[1] === tenantId
    );
}

export function getAccountsByTenantId(
  msalInstance: IPublicClientApplication,
  tenantId: string
) {
  return msalInstance.getAllAccounts().filter((a) => a.tenantId === tenantId);
}
