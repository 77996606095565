import { getVersionBuildId } from "@components/version";
import {
  Archive,
  Depot,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/chunkstore/v1/chunkstore_pb";
import { Device } from "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/device_registry_pb";
import { Operation } from "@xboxstudios/hermes-apis/xboxstudios/hermes/operations/v1/operations_pb";
import {
  Channel,
  Product,
  Version,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/product/v1/product_registry_pb";
import { getPausedUntil, isPaused, pause, unPause } from "./device";
import { HermesPermissions, Permissions } from "./iam/permission";
import {
  getDepotNameFromResource,
  getDeviceNameFromResource,
  getInstallNamesFromOperation,
  getOrgNameFromResource,
  getProductNameFromResource,
  segmentResourceName,
} from "./names";
import { getProductImageUri, ProductImageSize } from "./product";

/**
 * Adds additional utility methods to Hermes class prototypes
 */
export function addHermesClassExtensions() {
  addProductRegistryExtensions();
  addDeviceRegistryExtensions();
  addOperationRegistryExtensions();
  addChunkstoreExtensions();
}

export interface ResourceGeneralExtensions {
  getId(): string;
}

// Shared resource extensions
export interface ResourcePermissionsExtensions {
  getPermissions(): Permissions;
}

export interface OrgResourceExtensions {
  getOrgName(): string;
}

// Product Registry
export interface ProductRegistryResourceExtensions
  extends ResourceGeneralExtensions,
    ResourcePermissionsExtensions,
    OrgResourceExtensions {}

declare module "@xboxstudios/hermes-apis/xboxstudios/hermes/product/v1/product_registry_pb" {
  interface Product extends ProductRegistryResourceExtensions {
    getImageUrl(size: ProductImageSize): string;
  }
  interface Channel extends ProductRegistryResourceExtensions {
    getProductName(): string;
  }
  interface Version extends ProductRegistryResourceExtensions {
    getProductName(): string;
    getBuildId(): string;
  }
}

[Product, Channel, Version].forEach((r) => {
  r.prototype.getOrgName = function () {
    return getOrgNameFromResource(this.getName());
  };
  r.prototype.getPermissions = function () {
    return HermesPermissions.Product;
  };

  r.prototype.getId = function () {
    return segmentResourceName(this.getName(), 1, -1);
  };
});

function addProductRegistryExtensions() {
  Product.prototype.getImageUrl = function (imageSize: ProductImageSize) {
    return getProductImageUri(this, imageSize);
  };

  Channel.prototype.getProductName = function () {
    return getProductNameFromResource(this.getName());
  };

  Version.prototype.getProductName = function () {
    return getProductNameFromResource(this.getName());
  };

  Version.prototype.getBuildId = function () {
    return getVersionBuildId(this);
  };
}

// Device Registry
declare module "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/device_registry_pb" {
  interface Device
    extends ResourceGeneralExtensions,
      ResourcePermissionsExtensions {
    getPausedUntil(): ReturnType<typeof getPausedUntil>;
    isPaused(): ReturnType<typeof isPaused>;
    unPause(): ReturnType<typeof unPause>;
    pause(daysPaused: number): ReturnType<typeof pause>;
  }
}

function addDeviceRegistryExtensions() {
  Device.prototype.getPermissions = function () {
    return HermesPermissions.Device;
  };

  Device.prototype.getPausedUntil = function () {
    return getPausedUntil(this);
  };

  Device.prototype.isPaused = function () {
    return isPaused(this);
  };

  Device.prototype.unPause = function () {
    return unPause(this);
  };

  Device.prototype.pause = function (daysPaused) {
    return pause(this, daysPaused);
  };

  Device.prototype.getId = function () {
    return segmentResourceName(this.getName(), 1, -1);
  };
}

// Operations
declare module "@xboxstudios/hermes-apis/xboxstudios/hermes/operations/v1/operations_pb" {
  interface Operation
    extends ResourceGeneralExtensions,
      ResourcePermissionsExtensions {
    getDeviceName(): string;
    getInstallNames(): ReturnType<typeof getInstallNamesFromOperation>;
  }
}

function addOperationRegistryExtensions() {
  Operation.prototype.getDeviceName = function () {
    return getDeviceNameFromResource(this.getName());
  };

  Operation.prototype.getPermissions = function () {
    return HermesPermissions.Device;
  };

  Operation.prototype.getInstallNames = function () {
    return getInstallNamesFromOperation(this);
  };

  Operation.prototype.getId = function () {
    return segmentResourceName(this.getName(), 1, -1);
  };
}

// Chunkstore
export interface ChunkstoreExtensions extends OrgResourceExtensions {}

declare module "@xboxstudios/hermes-apis/xboxstudios/hermes/chunkstore/v1/chunkstore_pb" {
  interface Depot
    extends ResourceGeneralExtensions,
      ResourcePermissionsExtensions {}
  interface Archive
    extends ResourceGeneralExtensions,
      ResourcePermissionsExtensions {
    getDepotName(): string;
  }
}

function addChunkstoreExtensions() {
  Depot.prototype.getPermissions = function () {
    return HermesPermissions.ChunkStore;
  };

  Depot.prototype.getId = function () {
    return segmentResourceName(this.getName(), 1, -1);
  };

  Archive.prototype.getPermissions = function () {
    return HermesPermissions.ChunkStore;
  };

  Archive.prototype.getDepotName = function () {
    return getDepotNameFromResource(this.getName());
  };

  Archive.prototype.getId = function () {
    return segmentResourceName(this.getName(), 1, -1);
  };
}
