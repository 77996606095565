import { useColorMode, useTheme } from "@chakra-ui/react";
import { ThemeProvider } from "@chakra-ui/react";
import { FC } from "react";

/**
 * "Hack" to change the color values in light/dark mode
 * see: https://github.com/chakra-ui/chakra-ui/issues/1162#issuecomment-682270232
 */
export const DynamicColorMode: FC<any> = (props) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  const colorModeTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      ...theme.colors.modes?.[colorMode],
    },
    initialColorMode: colorMode,
    useSystemColorMode: true,
  };

  return <ThemeProvider {...props} theme={colorModeTheme} />;
};
