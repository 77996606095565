import { AccountPicker } from "@auth/AccountPicker";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { CustomBox } from "@components/CustomBox";
import useAccountStore from "@components/graph-picker/account-store";
import { TenantDisplayName } from "@components/graph-picker/TenantDisplayName";
import { TenantLogo } from "@components/graph-picker/TenantLogo";
import Logo from "@components/Logo";
import { BsCheckCircleFill } from "react-icons/bs";
import { VscChevronDown } from "react-icons/vsc";
import { useHistory } from "react-router-dom";

export const AdminConsentPage = () => {
  const account = useAccountStore((s) => s.account);

  const tenantId = account?.tenantId || "";

  const isAdminConsentSuccess = useIsAdminConsentSuccess();

  return (
    <Center w="100vw" h="100vh">
      <CustomBox
        variant="card"
        display="flex"
        flexDirection="column"
        gap={6}
        p={6}
      >
        <Flex align="center" gap={2} ml={-2}>
          <Logo w={6} />
          <Heading size="md" color="neutral.100">
            Hermes
          </Heading>
        </Flex>
        {!!isAdminConsentSuccess ? (
          <AdminConsentSuccess tenantId={tenantId} />
        ) : (
          <RequestAdminConsent tenantId={tenantId} />
        )}
      </CustomBox>
    </Center>
  );
};

function RequestAdminConsent({ tenantId }: { tenantId: string }) {
  const history = useHistory();
  const navigateToAdminConsent = () =>
    window.location.assign(
      `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=1e2c18df-5769-40dd-aa6d-fda44877271e&redirect_uri=${window.location.origin}/adminconsent`
    );
  return (
    <>
      <Heading size="lg" color="neutral.50">
        Admin Consent
      </Heading>
      <Flex direction="column" gap={2} px={4}>
        <Heading size="xs" color="neutral.100" textAlign="start">
          Organization
        </Heading>

        <CustomBox
          variant="card"
          display="flex"
          alignItems="center"
          boxShadow="md"
          gap={2}
          p={3}
          ml={2}
        >
          <TenantLogo tenantId={tenantId} w={6} h={6} />
          <TenantDisplayName
            tenantId={tenantId}
            fontSize="md"
            fontWeight="semibold"
            flex="1"
            isTruncated
          />
          <ChangeOrg />
        </CustomBox>
      </Flex>

      <Container fontSize="lg" fontWeight="light" ml={1} textAlign="start">
        Admin consent is required for users in your organization to use Hermes.
        If you are an admin for your organization you may grant consent on
        behalf of your organization.
      </Container>
      <Flex justify="flex-end" gap={2}>
        <Button size="sm" onClick={() => history.push("/")}>
          Cancel
        </Button>
        <Button onClick={navigateToAdminConsent} size="sm" colorScheme="blue">
          Grant consent
        </Button>
      </Flex>
    </>
  );
}

function AdminConsentSuccess({ tenantId }: { tenantId: string }) {
  const history = useHistory();

  return (
    <Flex direction="column" gap={4}>
      <Heading size="md" display="flex" alignItems="center">
        Admin Consent Granted
      </Heading>

      <Flex direction="column" gap={2} px={4}>
        <Heading size="xs" color="neutral.100" textAlign="start">
          Organization
        </Heading>
        <CustomBox
          variant="card"
          display="flex"
          alignItems="center"
          gap={2}
          p={3}
          ml={2}
        >
          <Icon as={BsCheckCircleFill} color="green.500" mr={2} />
          <TenantLogo tenantId={tenantId} w={6} h={6} />
          <TenantDisplayName
            tenantId={tenantId}
            fontSize="md"
            fontWeight="semibold"
            isTruncated
          />
        </CustomBox>
      </Flex>
      <Container ml={1} textAlign="start" display="flex" flexDirection="column">
        <Text fontSize="lg" fontWeight="light" mb={4}>
          Users in{" "}
          <TenantDisplayName
            as="span"
            tenantId={tenantId}
            fontWeight="semibold"
          />{" "}
          may now access Hermes!
        </Text>

        <Button
          colorScheme="green"
          size="sm"
          alignSelf="flex-end"
          onClick={() => history.push("/")}
        >
          Start using Hermes
        </Button>
      </Container>
    </Flex>
  );
}

function ChangeOrg() {
  const msal = useMsal();
  const { account, setAccount } = useAccountStore();

  return (
    <Popover closeOnBlur={true}>
      {({ onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <IconButton
                size="sm"
                variant="ghost"
                icon={<VscChevronDown />}
                aria-label="change organization"
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody p={4}>
                <AccountPicker
                  activeAccount={account}
                  onChange={(a) => {
                    setAccount(a);
                    // The MSAL hooks aren't reactive but we need to set the active account
                    // here so the proper tenant info is preserved after the amdmin consent
                    // redirects
                    msal.instance.setActiveAccount(a);
                    onClose();
                  }}
                />
              </PopoverBody>
            </PopoverContent>
          </>
        );
      }}
    </Popover>
  );
}

function useIsAdminConsentSuccess() {
  const params = new URL(window.location.href).searchParams;
  return params.get("admin_consent")?.toLowerCase() === "true";
}
