/**
 * Config and utility functions for connecting to Hermes resources
 * in different environments.
 */

import { BrowserAuthOptions } from "@azure/msal-browser";

export type HermesEnvironment = "prod" | "dev" | "local" | "xdev";

export interface HermesEnvironmentConfig {
  name: HermesEnvironment;
  info: {
    colorScheme: string;
    display: boolean;
  };
  hermesApi: {
    endpoint: string;
    /** Prefix for Hermes API scopes */
    appIdUri: string;
  };
  msalAuthConfig: BrowserAuthOptions;
  appInsights: {
    instrumentationKey: string;
  };
}

const local: HermesEnvironmentConfig = {
  name: "local",
  info: {
    colorScheme: "yellow",
    display: import.meta.env.DEV,
  },
  hermesApi: {
    endpoint: "https://localhost:5443",
    appIdUri: "https://hermes.api.xboxstudios.com/",
  },
  appInsights: {
    instrumentationKey: "f0f74a0c-075a-43ac-ac50-0c096486cf3c",
  },
  msalAuthConfig: {
    clientId: "5dbf59cd-2eb1-48b0-aed4-e6ba1fa43acd",
    authority: "https://login.microsoftonline.com/organizations",
  } as BrowserAuthOptions,
};

const dev: HermesEnvironmentConfig = {
  name: "dev",
  info: {
    colorScheme: "red",
    display: true,
  },
  hermesApi: {
    endpoint: "https://api.dev.hermes.staging.xboxstudios.com",
    appIdUri: "https://hermes.api.xboxstudios.com/",
  },
  appInsights: {
    instrumentationKey: "f0f74a0c-075a-43ac-ac50-0c096486cf3c",
  },
  msalAuthConfig: {
    clientId: "5dbf59cd-2eb1-48b0-aed4-e6ba1fa43acd",
    authority: "https://login.microsoftonline.com/organizations",
  } as BrowserAuthOptions,
};

const prod: HermesEnvironmentConfig = {
  name: "prod",
  info: {
    colorScheme: "green",
    display: true,
  },
  hermesApi: {
    endpoint: "https://api.hermes.xboxstudios.com",
    appIdUri: "https://hermes.api.xboxstudios.com/",
  },
  appInsights: {
    instrumentationKey: "186c67fe-56a1-45c6-b6dc-1a5a2076e920",
  },
  msalAuthConfig: {
    clientId: "5dbf59cd-2eb1-48b0-aed4-e6ba1fa43acd",
    authority: "https://login.microsoftonline.com/organizations",
  } as BrowserAuthOptions,
};

const xdev: HermesEnvironmentConfig = {
  name: "xdev",
  info: {
    colorScheme: "orange",
    display: true,
  },
  hermesApi: {
    endpoint: "https://api.x.dev.hermes.staging.xboxstudios.com",
    appIdUri: "https://hermes.api.xboxstudios.com/",
  },
  appInsights: {
    instrumentationKey: "446d9e9a-3e5f-442c-85ab-acae414723e9",
  },
  msalAuthConfig: {
    clientId: "5dbf59cd-2eb1-48b0-aed4-e6ba1fa43acd",
    authority: "https://login.microsoftonline.com/organizations",
  } as BrowserAuthOptions,
};

export const environments: Record<HermesEnvironment, HermesEnvironmentConfig> =
  {
    prod,
    dev,
    local,
    xdev,
  };

// Key for setting/fetching the user's environment choice.
const envKey = "hermes.environment";

/**
 * Retrieves the active environment from the URL or session storage.
 * Defaults to the Prod environment if no environment is set.
 */
export const getHermesEnvironment = () => {
  const urlEnvName = new URLSearchParams(window.location.search)
    ?.get("env")
    ?.toLowerCase();
  if (urlEnvName) {
    const env = environments[urlEnvName as HermesEnvironment];
    if (env) {
      // Set hermes environment in session storage to preserve
      // env on navigation
      window.sessionStorage.setItem(envKey, urlEnvName);
      return env;
    }
  }

  const activeEnvName = window.sessionStorage.getItem(envKey)?.toLowerCase();
  if (activeEnvName && environments[activeEnvName as HermesEnvironment]) {
    return environments[activeEnvName as HermesEnvironment];
  }

  return environments.prod;
};

/**
 * Updates the hermes environment settings and reloads the page.
 */
export const setHermesEnvironment = (newEnvName: HermesEnvironment) => {
  if (newEnvName.toLowerCase() !== getHermesEnvironment().name) {
    window.sessionStorage.setItem(envKey, newEnvName);
    // Page reload is necessary to apply the new config values to the
    // relevent consumers. This isn't the best UX but it greatly simplifies
    // coordinating updates to the various environment config consumers (MSAL, AppInsights, Hermes API, etc...).
    // This is especially true in situations where page routing and/or external data
    // on the active page is likely to change accross environments.
    window.location.reload();
  }
};

/** Gets the name of the current Hermes environment if not Prod. */
export const getCurrentEnvDisplayName = () => {
  const currentEnv = getHermesEnvironment();

  // no need to show the environment if we're using the production environment
  if (currentEnv.name === environments.prod.name) {
    return null;
  }

  return currentEnv.name;
};
