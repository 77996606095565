import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import { AccountInfo, ClientAuthError } from "@azure/msal-common";
import { getHermesEnvironment } from "@env/envrionment";
import {
  getAccountsByTenantId,
  getMemberAccountsByTenantId,
} from "./publicClientExtensions";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    ...getHermesEnvironment().msalAuthConfig,
    redirectUri: "/oauth2/callback",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    navigateFrameWait: 0,
  },
};

export function getTenantAuthority(tenantId: string) {
  return `https://login.microsoftonline.com/${tenantId}`;
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const defaultRequest: SilentRequest = {
  scopes: ["User.Read"],
};

/**
 * Note: This is only for initializing the instance and
 * accessing the instance outside of a react component.
 * If you need the instance in a component you can use the `useMsal` hook
 * from the `@azure/msal-browser` library
 */
export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Extra logic for msal events.
 *
 * When using `@azure/msal-react` this should be registered within a React component
 * since we're calling loginRedirect. MSAL-react recommends not calling
 * this outside of react components.
 *
 * see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#what-can-i-do-outside-of-azuremsal-react-context
 *
 * Info on all available Events
 * see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
export const registerMsalEventHandler = (
  instance: IPublicClientApplication
) => {
  return instance.addEventCallback(async (event: EventMessage) => {
    // Set active account on successful login and redirect (aka silent token refresh)
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      if (event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        // NOTE: we don't want to set the active account if the interaction type is a popup
        // since this happens in the user selection menu and results in a confusing experience
        if (account && event.interactionType !== InteractionType.Popup) {
          instance.setActiveAccount(account);
        }
      }
    }
    if (event.eventType === EventType.HANDLE_REDIRECT_END) {
      // Set default account after token refresh (ex user leaves tab open
      // and returns after token expires) or msal info is cleared from browser storage.
      // There is no payload on redirect end.
      if (!instance.getActiveAccount()) {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          const defaultAccount = accounts[0];
          instance.setActiveAccount(defaultAccount);
        } else {
          await instance.handleRedirectPromise();
          await instance.ssoSilent(defaultRequest);
        }
      }
    }
    if (
      event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
      event.eventType === EventType.SSO_SILENT_FAILURE
    ) {
      let isActiveAccount = false;
      const payload = event.payload as AuthenticationResult;
      const account = payload?.account;
      if (!!account) {
        isActiveAccount =
          account.localAccountId ===
          instance.getActiveAccount()?.localAccountId;
      }
      // Only attempt to login again if token failure is silent and the account is the active account
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/74571
      const isActiveAccountSilentFailure =
        event.interactionType === "silent" && isActiveAccount;

      // If the MSAL browser cache is cleared and a request is made
      // we'll need to log in again
      const isClientAuthFailure =
        event.error instanceof ClientAuthError &&
        !instance.getAllAccounts().length;

      if (isActiveAccountSilentFailure || isClientAuthFailure) {
        await instance.handleRedirectPromise();
        await instance.acquireTokenRedirect({ ...defaultRequest, account });
      }
    }
  });
};

/**
 * Acquire an access token from Entra. If the token can't be acquired
 * silently it will attempt to acquire it via a redirect. This typically
 * occurs when the user's local storage has been cleared.
 * @param {SilentRequest} req
 * @returns An access token string
 *
 *  >_Note: This is only for getting access tokens outside of the React MSAL context.
 *       If you need an access token in a component use the `useMsal()` hook
 *       to get the instance and call acquireTokenSilent() from there._
 */
export const getAccessToken = async (req: SilentRequest) => {
  // IMPORTANT: Since we're potentially calling loginRedirect() here
  // outside of a react component we need to make sure that any active
  // interactions are finished, otherwise we may end up in an infinite
  // redirect loop
  const redirectResult = await msalInstance.handleRedirectPromise();

  // acquireTokenSilent() needs an account in the request.
  // The active account context gets lost after page redirects
  // so we need to make sure that it's set before proceeding.
  if (redirectResult && redirectResult.account) {
    msalInstance.setActiveAccount(redirectResult.account);
  }

  // MSAL/Graph will only use the home tenant from the token for some reason.
  // If there's a specific authority called out on the token, look for an account
  // with a token for that issuer and add it to the request
  if (req.authority && !req.account) {
    const tenantId = req.authority.split("/").pop();
    if (!!tenantId && tenantId !== "organizations") {
      const memberAcct = getMemberAccountsByTenantId(msalInstance, tenantId)[0];
      if (memberAcct) {
        req.account = memberAcct;
      } else {
        const guestAcct = getAccountsByTenantId(msalInstance, tenantId)[0];
        if (guestAcct) {
          req.account = guestAcct;
        }
      }
    }
  } else {
    // Similiar to above, ensure that the correct tokens are being used
    // for the active account, since MSAL will default to using tokens for
    // the active account's home tenant
    // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/57087
    const activeAcct = msalInstance.getActiveAccount();
    if (activeAcct) {
      req.account = activeAcct;

      if (!req.authority) {
        req.authority = getTenantAuthority(activeAcct.tenantId);
      }
    } else {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const defaultAccount = accounts[0];
        msalInstance.setActiveAccount(defaultAccount);
        req.account = defaultAccount;

        if (!req.authority) {
          req.authority = getTenantAuthority(defaultAccount.tenantId);
        }
      } else {
        // If there are no accounts, we need to log in again
        return msalInstance.acquireTokenRedirect(req);
      }
    }
  }

  return msalInstance.acquireTokenSilent(req).then((res: any) => {
    return res.accessToken;
  });
};

/**
 * Returns a unique account ID from the users tenant and local account IDs
 */
export function getUniqueAccountId(account: AccountInfo) {
  return `${account.tenantId}.${account.localAccountId}`;
}
