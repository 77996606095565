/**
 * See this blog post for how interceptors are constructed and applied
 * https://grpc.io/blog/grpc-web-interceptor/
 *
 */

import * as grpc from "grpc-web";

import { getAppInsights, SeverityLevel } from "@logs/appInsights";
import { IDependencyTelemetry } from "@microsoft/applicationinsights-common";

interface MethodInfo {
  name?: string;
}

class LoggingUnaryInterceptor implements grpc.UnaryInterceptor<any, any> {
  intercept(
    request: grpc.Request<any, any>,
    invoker: (
      request: grpc.Request<any, any>
    ) => Promise<grpc.UnaryResponse<any, any>>
  ) {
    // TODO: We may want to disable the auto-Ajax-captures as it duplicates this
    // and doesn't have the proper context to treat it as a grpc call. Or we could
    // just disable it here and leave the ajax one if we find it's sufficient.
    // For now I'm leaving both so we can take a look.

    var md = request.getMethodDescriptor() as MethodInfo;
    var ap = getAppInsights();
    var depInfo = {
      startTime: new Date(),
      data: md.name,
    } as IDependencyTelemetry;

    // Before the call.
    return invoker(request).then((response: grpc.UnaryResponse<any, any>) => {
      // After the call.
      const code = response.getStatus().code;
      if (code !== grpc.StatusCode.OK) {
        ap.trackException({
          exception: new Error(response.getStatus().details),
          severityLevel: SeverityLevel.Error,
        });
        depInfo.responseCode = code;
        depInfo.success = false;
      } else {
        depInfo.success = true;
      }
      ap.trackDependencyData(depInfo);
      return response;
    });
  }
}

class LoggingStreamInterceptor implements grpc.StreamInterceptor<any, any> {
  intercept(
    request: grpc.Request<any, any>,
    invoker: (request: grpc.Request<any, any>) => grpc.ClientReadableStream<any>
  ) {
    class InterceptedStream implements grpc.ClientReadableStream<any> {
      stream: grpc.ClientReadableStream<any>;
      constructor(stream: grpc.ClientReadableStream<any>) {
        this.stream = stream;
      }

      on(eventType: string, callback: any) {
        if (eventType === "data") {
          this.stream.on(eventType, callback);
        } else if (eventType === "error") {
          const newCallback = (response: any) => {
            getAppInsights().trackException({
              exception: response, // TODO: I think this will not work, but I'm not sure of response's type here.
              severityLevel: SeverityLevel.Error,
            });
          };
          this.stream.on(eventType, newCallback);
        } else if (eventType === "metadata") {
          this.stream.on(eventType, callback);
        } else if (eventType === "status") {
          this.stream.on(eventType, callback);
        } else if (eventType === "end") {
          this.stream.on(eventType, callback);
        }

        return this;
      }

      removeListener(eventType: string, callback: any) {}

      cancel() {}
    }

    return new InterceptedStream(invoker(request));
  }
}

export { LoggingUnaryInterceptor, LoggingStreamInterceptor };
