import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useDebouncedInput } from "@components/form/useDebouncedInput";
import { VscCheck, VscChromeClose } from "react-icons/vsc";
import { useQuery } from "react-query";

export interface TenantLookupProps {
  onChange: (tenantId: string) => unknown;
}

/**
 * Checks if a tenant ID or domain name is linked to a valid Entra organization.
 * Exposes the tenant ID when valid.
 */
export const TenantLookup = ({ onChange }: TenantLookupProps) => {
  const { value, debouncedValue, setValue } = useDebouncedInput({
    debounceTime: 300,
    initialValue: "",
  });

  const tenantQuery = useQuery({
    queryKey: ["tenant", "info", debouncedValue],
    queryFn: () => {
      return fetch(
        `https://login.microsoftonline.com/${debouncedValue}/.well-known/openid-configuration`
      )
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            onChange("");
            throw d;
          }
          const tenantId = (d.issuer as string)
            .split("/")
            .slice(-2, -1)
            .join("");
          onChange(tenantId);
          return d;
        });
    },
    enabled: !!debouncedValue,
  });

  return (
    <Flex direction="column">
      <InputGroup mb={2}>
        <Input
          placeholder="Enter tenant domain or tenant ID"
          backgroundColor="neutral.800"
          autoComplete="on"
          value={value}
          onChange={(e) => {
            if (!e.target.value && debouncedValue !== "") {
              onChange("");
            }
            setValue(e.target.value);
          }}
        />
        <InputRightElement>
          {!!tenantQuery.isFetching ? (
            <Spinner size="xs" />
          ) : !!tenantQuery.data &&
            tenantQuery.isSuccess &&
            debouncedValue.length > 0 ? (
            <Icon as={VscCheck} color="green.500" />
          ) : !!tenantQuery.error &&
            !tenantQuery.isFetching &&
            debouncedValue.length > 0 ? (
            <Icon as={VscChromeClose} color="red.500" />
          ) : null}
        </InputRightElement>
      </InputGroup>
      <Text px={2} fontSize="sm" color="neutral.50" minH={6}>
        {!!tenantQuery.data &&
          "Tenant ID: " +
            (tenantQuery.data.issuer as string).split("/").slice(-2, -1)}
      </Text>
    </Flex>
  );
};
