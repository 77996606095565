/**
 * Package docs: https://date-fns.org/docs/Getting-Started
 */

import {
  addSeconds,
  compareAsc,
  formatDistanceToNow,
  sub,
  startOfDay,
} from "date-fns";

import { format as formatTz } from "date-fns-tz";

/**
 * Converts seconds to a JS Date
 * @param seconds time in seconds
 * @returns A Date
 */
export const getDateFromSeconds = (seconds: number) => {
  return addSeconds(new Date(0), seconds);
};

/**
 * Formats a date in human readable form
 * @param seconds Time in seconds
 * @param dateFormat Optional: String of tokens to format the date
 * @returns A formatted date string
 */
export const getFormattedDateFromSeconds = (
  seconds: number,
  dateFormat = "MMM dd, yyyy, hh:mm b z"
) => {
  const date = getDateFromSeconds(seconds);
  return formatTz(date, dateFormat);
};

export interface TimeFromNowOptions {
  addSuffix?: boolean | undefined;
  includeSeconds?: boolean | undefined;
  locale?: Locale | undefined;
}

/**
 * Gets a human readable time/date range from the current time
 * @param seconds The time in seconds to get the range from
 * @returns A human readable time/date range from now
 */
export const getTimeFromNow = (
  seconds: number | undefined,
  options?: TimeFromNowOptions
) => {
  if (seconds === undefined) {
    return "";
  }
  const date = getDateFromSeconds(seconds);
  return formatDistanceToNow(date, { addSuffix: true });
};

export interface TimeFromNowConditionalOptions extends TimeFromNowOptions {
  dateFormat?: string;
}

/**
 * Conditionally returns a human readable time/date range or formatted date.
 * Times within the provided range are returned as a human readable time range. Times after the
 * provided range are returned as a formatted date time. Range comparisons have
 * a resolution of a single day.
 * @param seconds The time in seconds to get the range/date from
 * @param range The range at which the value should switch from a human readable
 * time range to a formatted date time.
 * @param dateFormat Optional: The format for the date time.
 * @returns
 */
export const getTimeFromNowConditional = (
  seconds: number | undefined,
  range: Duration,
  options?: TimeFromNowConditionalOptions
) => {
  if (seconds === undefined) {
    return "";
  }
  let time = getDateFromSeconds(seconds);
  let compareTime = sub(Date.now(), range);

  time = startOfDay(time);
  compareTime = startOfDay(compareTime);

  if (compareAsc(time, compareTime) <= 0) {
    return getFormattedDateFromSeconds(seconds, options?.dateFormat);
  } else {
    return getTimeFromNow(seconds);
  }
};
