/**
 * See wiki for annotation key guidelines and Hermes specific annotation keys
 * https://dev.azure.com/xboxstudios/Hermes/_wiki/wikis/Docs/543
 * https://dev.azure.com/xboxstudios/Hermes/_wiki/wikis/Docs/604
 */

export const HermesAnnotationKeys = {
  /** Indicates a time-restricted pause state on a device or operation */
  pause: "hermes.xboxstudios.com/paused-until" as string,
  /** Indicates the property key that holds the build id on a Version  */
  buildId: "hermes.xboxstudios.com/build-id-key" as string,
};
