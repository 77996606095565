import {
  gRPCListQueryOptions,
  ListQueryOptions,
  ResourceType,
} from "@utils/hermes-api/types";
import { QueryKey } from "react-query";

export const getDefaultListQueryFilters = (): gRPCListQueryOptions => ({
  showDeleted: false,
  pageSize: undefined,
  pageToken: undefined,
});

// TODO: use the filsters specific for each type of query
const singleKey = "single";
const listKey = "list";
const infiniteKey = "infinite";
const policyKey = "policy";
const permissionKey = "permission";

const deviceKey = "device";
const codeKey = "code";
export const deviceKeys = {
  /** Target all device queries */
  all: [deviceKey] as QueryKey,
  allLists: [deviceKey, listKey] as QueryKey,
  // TODO: Determine how to handle the unique list filters for these queries
  /** Target a filtered device list query for an organization */
  listFiltered: (filters: ListQueryOptions) => {
    return [...deviceKeys.allLists, filters] as QueryKey;
  },
  infiniteList: [deviceKey, listKey, infiniteKey],
  allSingle: [deviceKey, singleKey] as QueryKey,
  /** Target a single device query */
  single: (deviceName: string) => {
    return [...deviceKeys.allSingle, deviceName] as QueryKey;
  },
  iamPolicy: (deviceName: string) => {
    return [deviceKey, policyKey, deviceName] as QueryKey;
  },
  iamPermissions: (deviceName: string, permissions: string[]) => {
    return [deviceKey, permissionKey, deviceName, ...permissions] as QueryKey;
  },
  allCode: [deviceKey, singleKey, codeKey] as QueryKey,
  code: (code: string) => {
    return [...deviceKeys.allCode, code] as QueryKey;
  },
};

const orgKey = "org";
export const orgKeys = {
  all: [orgKey] as QueryKey,
  allLists: [orgKey, listKey] as QueryKey,
  allSingle: [orgKey, singleKey] as QueryKey,
  single: (orgName: string) => {
    return [...orgKeys.allSingle, orgName] as QueryKey;
  },
};

const productKey = "product";
export const productKeys = {
  /** Target all product queries */
  all: [productKey] as QueryKey,
  allLists: [productKey, listKey],
  /** Target all product list queries for an organization */
  list: (orgName: string) => {
    return [...productKeys.allLists, orgName] as QueryKey;
  },
  /** Target a single filtered product list query for an organization */
  listFilter: (orgName: string, filters: ListQueryOptions) => {
    return [...productKeys.list(orgName), filters] as QueryKey;
  },
  allSingle: [productKey, singleKey],
  /** Target a single product query for an organization */
  single: (productName: string) => {
    return [...productKeys.allSingle, productName] as QueryKey;
  },
};

const channelKey = "channel";
export const channelKeys = {
  /** Target all channel queries */
  all: [channelKey] as QueryKey,
  allLists: [channelKey, listKey],
  /** Target all channels lists for a product */
  list: (productName: string) => {
    return [...channelKeys.allLists, productName] as QueryKey;
  },
  /** Target a filtered channel list for a product */
  listFiltered: (productName: string, filters: ListQueryOptions) => {
    const queryFilters = {
      ...getDefaultListQueryFilters(),
      ...filters,
    };
    return [...channelKeys.list(productName), queryFilters] as QueryKey;
  },
  allSingle: [channelKey, singleKey],
  /** Target a single channel query */
  single: (channelName: string) => {
    return [...channelKeys.allSingle, channelName] as QueryKey;
  },
};

const versionKey = "version";
export const versionKeys = {
  /** Target all version queries */
  all: [versionKey] as QueryKey,
  /** Target all version list queries */
  allLists: [versionKey, listKey],
  /** Targets all queries for versions under a product.
   * This is meant to target existing queries only.
   */
  list: (productName: string) => {
    return [...versionKeys.allLists, productName] as QueryKey;
  },
  /** Target a specific version list query for a product */
  listFiltered: (productName: string, filters: ListQueryOptions = {}) => {
    const allFilters = {
      ...getDefaultListQueryFilters(),
      ...filters,
    };
    return [...versionKeys.list(productName), allFilters] as QueryKey;
  },
  allSingle: [versionKey, singleKey],
  /** Target a single version query */
  single: (versionName: string) => {
    return [...versionKeys.all, versionName] as QueryKey;
  },
};

const operationKey = "operation";
export const operationKeys = {
  /** Target all queries of this type */
  all: [operationKey] as QueryKey,
  allLists: [operationKey, listKey] as QueryKey,
  list: (deviceName: string) => {
    return [...operationKeys.allLists, deviceName] as QueryKey;
  },
  listFiltered: (deviceName: string, filters: ListQueryOptions) => {
    return [...operationKeys.list(deviceName), filters] as QueryKey;
  },
  allSingle: [versionKey, singleKey],
  single: (operationName: string) => {
    return [...operationKeys.allSingle, operationName] as QueryKey;
  },
};

const iamKey = "iam";
export const iamKeys = {
  all: [iamKey] as QueryKey,
  single: (resourceName: string) => {
    return [...iamKeys.all, resourceName] as QueryKey;
  },
  permissionsCheck: (resourceName: string, permissions: string[]) => {
    return [...iamKeys.all, resourceName, "test", ...permissions] as QueryKey;
  },
};

const depotKey = "depot";
export const depotKeys = {
  all: [depotKey] as QueryKey,
  allLists: [depotKey, listKey] as QueryKey,
  list: (parentName: string) => {
    return [...depotKeys.allLists, parentName] as QueryKey;
  },
  listFiltered: (parentName: string, filters: ListQueryOptions) => {
    return [...depotKeys.list(parentName), filters] as QueryKey;
  },
  single: (depotName: string) => {
    return [...depotKeys.all, depotName] as QueryKey;
  },
};

const archiveKey = "archive";
export const archiveKeys = {
  all: [archiveKey] as QueryKey,
  allLists: [archiveKey, listKey] as QueryKey,
  list: (parentName: string) => {
    return [...archiveKeys.allLists, parentName] as QueryKey;
  },
  listFiltered: (parentName: string, filters: ListQueryOptions) => {
    return [...archiveKeys.list(parentName), filters] as QueryKey;
  },
  single: (archiveName: string) => {
    return [...archiveKeys.all, archiveName] as QueryKey;
  },
};

const queryKeys = {
  device: deviceKeys,
  org: orgKeys,
  product: productKeys,
  channel: channelKeys,
  version: versionKeys,
  operation: operationKeys,
  iam: iamKeys,
  depot: depotKeys,
  archive: archiveKeys,
};

export function getQueryKeyForResource(resourceType: ResourceType) {
  return queryKeys[resourceType];
}
