import { getAccessToken } from "@auth/auth";
import { SubmitCommandMetadata } from "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/device_registry_pb";
import {
  CancelOperationRequest,
  DeleteOperationRequest,
  GetOperationRequest,
  ListOperationsRequest,
  Operation,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/operations/v1/operations_pb";
import { HermesApiPermissions } from "./api-permissions";
import { getHermesClient } from "./client";
import { ListQueryOptions, ListRequest } from "./types";

const getOperationsPromiseClient = () => {
  return getHermesClient().operationsPromiseClient;
};

const getToken = async (scopes: string[]) => await getAccessToken({ scopes });

const getMetadata = async (
  scopes: string[],
  additionalFields?: { [key: string]: string }
) => {
  const token = await getToken(scopes);
  return {
    Authorization: `Bearer ${token}`,
    ...additionalFields,
  };
};

export const getOperation = async (operationName: string) => {
  const client = getOperationsPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.operation]);
  const req = new GetOperationRequest();
  req.setName(operationName);
  return client.getOperation(req, metadata);
};

/**
 * Helper function to apply common request options for gRPC list requests.
 * @param req a gRPC request for a resource list
 * @param options common options found on list requests
 * @returns the original request
 */
export const setListOperationFilters = (
  req: ListRequest,
  options: ListQueryOptions
) => {
  if (req.setShowDeleted && options.showDeleted !== undefined) {
    req.setShowDeleted(options.showDeleted);
  }
  if (req.setPageSize && options.pageSize !== undefined) {
    req.setPageSize(options.pageSize);
  }
  if (req.setPageToken && options.pageToken !== undefined) {
    req.setPageToken(options.pageToken);
  }
  return req;
};

export const listOperations = async (
  resourceOwner: string,
  options: ListQueryOptions = {}
) => {
  const client = getOperationsPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.operation]);
  const req = new ListOperationsRequest();
  setListOperationFilters(req, options);
  req.setParent(resourceOwner);
  return client.listOperations(req, metadata);
};

export const getOperationMetadata = (operation: Operation) => {
  var metadata = operation?.getMetadata();
  if (metadata) {
    return metadata.unpack(
      SubmitCommandMetadata.deserializeBinary,
      metadata.getTypeName()
    );
  }
  return null;
};

export const cancelOperation = async (operationName: string) => {
  const client = getOperationsPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.operation]);
  const req = new CancelOperationRequest();
  req.setName(operationName);
  req.setAllowMissing(false);
  return client.cancelOperation(req, metadata);
};

export const deleteOperation = async (operationName: string) => {
  const client = getOperationsPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.operation]);
  const req = new DeleteOperationRequest();
  req.setName(operationName);
  req.setAllowMissing(true);
  return client.deleteOperation(req, metadata);
};
