const Drawer = {
  baseStyle: {
    body: {
      bg: "neutral.700",
    },
    footer: {
      bg: "neutral.700",
    },
    header: {
      bg: "neutral.700",
    },
  },
};

export default Drawer;
