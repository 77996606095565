import { getTenantAuthority } from "@auth/auth";
import { ResponseType } from "@microsoft/microsoft-graph-client";
import { getGraphClient } from "@utils/graph/graph";
import { useQuery, UseQueryOptions } from "react-query";
import { userKeys } from "./query-keys";

export const useUserPhotoQuery = (
  tenantId: string,
  userId: string,
  imageDimensions?: string,
  queryOptions: UseQueryOptions<string, Error> = {}
) => {
  const key = userKeys.singlePhoto(tenantId, userId, imageDimensions);
  const encodedUserId = encodeURIComponent(userId);
  const url = `/users/${encodedUserId}/${
    imageDimensions ? "photos/" + imageDimensions : "photo"
  }/$value`;
  return useQuery({
    queryKey: key,
    queryFn: () =>
      getGraphClient({
        scopes: ["user.read"],
        authority: !!tenantId ? getTenantAuthority(tenantId) : undefined,
      })
        .api(url)
        .responseType(ResponseType.BLOB)
        .get()
        .then((r) => {
          return (URL || webkitURL).createObjectURL(r);
        }),
    ...queryOptions,
  });
};
