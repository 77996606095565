import { Box, BoxProps, forwardRef, useStyleConfig } from "@chakra-ui/react";
import CB from "@theme/components/customBox";

export interface CustomBoxProps extends BoxProps {
  variant?: keyof typeof CB.variants;
}

export const CustomBox = forwardRef<CustomBoxProps, "div">((props, ref) => {
  const styles = useStyleConfig("CustomBox", props);

  return <Box __css={styles} {...props} ref={ref} />;
});
