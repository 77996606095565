import {
  ChunkStoreClient,
  ChunkStorePromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/chunkstore/v1/chunkstore_grpc_web_pb";
import {
  DeviceRegistryClient,
  DeviceRegistryPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/device_registry_grpc_web_pb";
import {
  IAMPolicyClient,
  IAMPolicyPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/iam/v1/iam_policy_grpc_web_pb";
import {
  IAMClient,
  IAMPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/iam/admin/v1/iam_grpc_web_pb";
import {
  OperationsClient,
  OperationsPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/operations/v1/operations_grpc_web_pb";
import {
  OrganizationsClient,
  OrganizationsPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/directory/v1/organizations_grpc_web_pb";
import {
  ProductRegistryClient,
  ProductRegistryPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/product/v1/product_registry_grpc_web_pb";
import {
  WatcherClient,
  WatcherPromiseClient,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/watcher/v0/watcher_grpc_web_pb";
import { LoggingStreamInterceptor, LoggingUnaryInterceptor } from "./logging";
import { getHermesEnvironment } from "@env/envrionment";
import { getAccessToken } from "@auth/auth";

const endpoint = getHermesEnvironment().hermesApi.endpoint;
let hermesClient: HermesClient | null = null;

class HermesClient {
  readonly chunkStorePromiseClient: ChunkStorePromiseClient;
  readonly chunkStoreClient: ChunkStoreClient;
  readonly deviceRegistryPromiseClient: DeviceRegistryPromiseClient;
  readonly deviceRegistryClient: DeviceRegistryClient;
  readonly iamPolicyClient: IAMPolicyClient;
  readonly iamPolicyPromiseClient: IAMPolicyPromiseClient;
  readonly iamClient: IAMClient;
  readonly iamPromiseClient: IAMPromiseClient;
  readonly operationsPromiseClient: OperationsPromiseClient;
  readonly operationsClient: OperationsClient;
  readonly organizationsPromiseClient: OrganizationsPromiseClient;
  readonly organizationsClient: OrganizationsClient;
  readonly productRegistryPromiseClient: ProductRegistryPromiseClient;
  readonly productRegistryClient: ProductRegistryClient;
  readonly watcherPromiseClient: WatcherPromiseClient;
  readonly watcherClient: WatcherClient;

  constructor(endpoint: string) {
    // Order of interceptors is significant, btw. See: https://grpc.io/blog/grpc-web-interceptor/
    var opts = {
      streamInterceptors: [new LoggingStreamInterceptor()],
      unaryInterceptors: [new LoggingUnaryInterceptor()],
    };

    var creds = null;

    this.chunkStorePromiseClient = new ChunkStorePromiseClient(
      endpoint,
      creds,
      opts
    );
    this.chunkStoreClient = new ChunkStoreClient(endpoint, creds);
    this.deviceRegistryPromiseClient = new DeviceRegistryPromiseClient(
      endpoint,
      creds,
      opts
    );
    this.deviceRegistryClient = new DeviceRegistryClient(endpoint, creds);
    this.iamPolicyPromiseClient = new IAMPolicyPromiseClient(
      endpoint,
      creds,
      opts
    );
    this.iamPolicyClient = new IAMPolicyClient(endpoint, creds);
    this.iamPromiseClient = new IAMPromiseClient(endpoint, creds, opts);
    this.iamClient = new IAMClient(endpoint, creds);
    this.operationsPromiseClient = new OperationsPromiseClient(
      endpoint,
      creds,
      opts
    );
    this.operationsClient = new OperationsClient(endpoint, creds, opts);
    this.organizationsPromiseClient = new OrganizationsPromiseClient(
      endpoint,
      creds,
      opts
    );
    this.organizationsClient = new OrganizationsClient(endpoint, creds, opts);
    this.productRegistryPromiseClient = new ProductRegistryPromiseClient(
      endpoint,
      creds,
      opts
    );
    this.productRegistryClient = new ProductRegistryClient(
      endpoint,
      creds,
      opts
    );
    this.watcherPromiseClient = new WatcherPromiseClient(endpoint, creds, opts);
    this.watcherClient = new WatcherClient(endpoint, creds, opts);
  }
}

const getHermesClient = (e = endpoint) => {
  if (!hermesClient) {
    hermesClient = new HermesClient(e);
  }

  return hermesClient;
};

const getMetadata = async (
  scopes: string[],
  additionalFields?: { [key: string]: string }
) => {
  const token = await getAccessToken({ scopes });
  return {
    Authorization: `Bearer ${token}`,
    ...additionalFields,
  };
};

export { HermesClient, getHermesClient, getMetadata };
