import { useMsal } from "@azure/msal-react";
import { registerMsalEventHandler } from "@auth/auth";
import { useEffect } from "react";

/**
 * Registers an MSAL event handler callback to add
 * custom functionality and/or logging to various
 * MSAL events.
 */
export const MsalEventHandler = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = registerMsalEventHandler(instance);
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // We only want to register the event handler once...
    // eslint-disable-next-line
  }, []);
  return null;
};
