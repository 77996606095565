import { defaultRequest, getTenantAuthority } from "@auth/auth";
import { Text, TextProps } from "@chakra-ui/react";
import { Organization } from "@microsoft/microsoft-graph-types";
import { getGraphClient } from "@utils/graph/graph";
import { ODataResponse } from "@utils/graph/types";
import { useQuery, UseQueryOptions } from "react-query";

export const useOrganizationInfo = (
  tenantId: string,
  queryOptions: UseQueryOptions<ODataResponse & Organization, Error> = {}
) => {
  return useQuery({
    queryKey: ["graph", "organization", "current", tenantId],
    queryFn: () =>
      getGraphClient({
        ...defaultRequest,
        authority: getTenantAuthority(tenantId),
      })
        .api(`/organization/${tenantId}`)
        .select("displayName, id")
        .get(),
    ...queryOptions,
  });
};

export interface TenantDisplayNameProps extends TextProps {
  tenantId: string;
}

export function TenantDisplayName({
  tenantId,
  ...rest
}: TenantDisplayNameProps) {
  const { data, isSuccess } = useOrganizationInfo(tenantId, {
    enabled: !!tenantId,
    retry: 0,
  });

  const tName = data?.displayName;

  return (
    <Text title={tenantId} {...rest}>
      {isSuccess && tName ? tName : tenantId}
    </Text>
  );
}
