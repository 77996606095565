const graphKey = "graph";
const userKey = "users";
const tenantKey = "tenants";
const groupKey = "groups";

/**
 * Query keys to help with caching of MS Graph data.
 *
 */
export const userKeys = {
  allTenant: (tenantId: string) => {
    return [graphKey, tenantId, userKey];
  },
  single: (tenantId: string, userId: string, odataOptions = "") => {
    return [...userKeys.allTenant(tenantId), userId, odataOptions].filter(
      Boolean
    );
  },
  singlePhoto: (tenantId: string, userId: string, size = "") => {
    return [...userKeys.single(tenantId, userId), "photo", size].filter(
      Boolean
    );
  },
};

export const tenantKeys = {
  allTenant: () => {
    return [groupKey, tenantKey];
  },
  single: (tenantId: string, odataOptions = "") => {
    return [...tenantKeys.allTenant(), tenantId, odataOptions].filter(Boolean);
  },
};

export const groupKeys = {
  allTenant: (tenantId: string) => {
    return [groupKey, tenantId, "groups"];
  },
  single: (tenantId: string, groupId: string, odataOptions = "") => {
    return [...groupKeys.allTenant(tenantId), groupId, odataOptions].filter(
      Boolean
    );
  },
  singlePhoto: (tenantId: string, groupId: string, size = "") => {
    return [...groupKeys.single(tenantId, groupId), "photo", size].filter(
      Boolean
    );
  },
};
