/**
 * See for usage: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
 */

import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { History } from "history";
import { getHermesEnvironment } from "@env/envrionment";

const reactPlugin = new ReactPlugin();

let appInsights: ApplicationInsights | null = null;

function getAppInsights() {
  if (!appInsights) {
    throw Error(
      "App insghts has not been initiated. You need to call initAppInsights first."
    );
  }
  return appInsights;
}

/**
 * Initializes app insights
 * @param history a history object. If using react-router, pass the same history object used in the router.
 */
function initAppInsights(history: History) {
  if (!!appInsights) {
    console.warn("AppInsights has already been initilized.");
  } else {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:
          getHermesEnvironment().appInsights.instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history },
        },
      },
    });
    appInsights.loadAppInsights();
  }
}

export { reactPlugin, initAppInsights, getAppInsights, SeverityLevel };
