import Alert from "./alert";
import Box from "./box";
import CustomBox from "./customBox";
import Drawer from "./drawer";
import Fade from "./fade";
import Input from "./input";
import SlideFade from "./slideFade";
import Textarea from "./textarea";

const Components = {
  Alert,
  Box,
  CustomBox,
  Drawer,
  Fade,
  Input,
  SlideFade,
  Textarea,
};

export default Components;
