import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

/**
 * Encapsulates logic for debouncing an input.
 */
export const useDebouncedInput = <T,>({
  debounceTime = 300,
  initialValue,
}: {
  debounceTime?: number;
  initialValue: T;
}) => {
  const [value, _setValue] = useState<T>(initialValue);
  const [debouncedValue, _setDebouncedValue] = useState<T>(initialValue);

  // eslint-disable-next-line
  const setDebouncedValue = useCallback(
    debounce((term: T) => _setDebouncedValue(term), debounceTime),
    []
  );

  const setValue = (val: T) => {
    _setValue(val);
    setDebouncedValue(val);
  };

  /** Resets both the value and debounced value instantly */
  const reset = () => {
    _setValue(initialValue);
    _setDebouncedValue(initialValue);
  };

  return {
    value,
    debouncedValue,
    setValue,
    reset,
  };
};
