import React from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import Logo from "./Logo";

export interface LoadSplashProps {
  children?: React.ReactNode;
  message?: string;
  secondaryText?: string;
}

function LoadSplash({ children, message, secondaryText }: LoadSplashProps) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      className="loadSplash"
    >
      <Flex direction="column" align="center" textAlign="center" gap={4}>
        <Logo w="16" />
        <Text fontSize="xl" fontWeight="light" color="neutral">
          {message}
        </Text>
        {secondaryText && <Text py={2}>{secondaryText}</Text>}
        <Spinner size="md" emptyColor="hermes.500" />
        {children}
      </Flex>
    </Flex>
  );
}

export default LoadSplash;
