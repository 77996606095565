import {
  ApproveDeviceRequest,
  Device,
  GetDeviceRequest,
  ReportProgressRequest,
  SubmitCommandRequest,
  UpdateDeviceRequest,
  DeleteDeviceRequest,
  UndeleteDeviceRequest,
  SearchDevicesRequest,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/device_registry_pb";
import { FieldMask } from "@xboxstudios/hermes-apis/google/protobuf/field_mask_pb";
import { Command } from "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/commands_pb";
import { getAccessToken } from "@auth/auth";
import { HermesApiPermissions } from "./api-permissions";
import { getHermesClient } from "./client";
import { ListQueryOptions } from "./types";
import { HermesAnnotationKeys } from "@components/annotations/annotationKeys";

const getDeviceRegistryPromiseClient = () => {
  return getHermesClient().deviceRegistryPromiseClient;
};

const getToken = async (scopes: string[]) => await getAccessToken({ scopes });

const getMetadata = async (
  scopes: string[],
  additionalFields?: { [key: string]: string }
) => {
  const token = await getToken(scopes);
  return {
    Authorization: `Bearer ${token}`,
    ...additionalFields,
  };
};

export const getDevice = async (deviceName: string) => {
  const client = getDeviceRegistryPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const reqBody = new GetDeviceRequest();
  reqBody.setName(deviceName);
  return client.getDevice(reqBody, metadata);
};

export const getDeviceByUserCode = async (userCode: string) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const reqBody = new GetDeviceRequest();
  reqBody.setName(`registeringDevices/${userCode}`);
  return client.getDevice(reqBody, metadata);
};

// Approve device
export const approveDevice = async (deviceName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const reqBody = new ApproveDeviceRequest();
  // is this the code?
  reqBody.setName(deviceName);

  return client.approveDevice(reqBody, metadata);
};

export const updateDevice = async (
  deviceEdits: Device,
  updatePaths: string[]
) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const updateMask = new FieldMask();
  // TODO: Find a way to automate the diffs
  // otherwise, all of the properties on the deviceEdits object gets
  // updated (which is no bueno...).
  if (updatePaths) {
    updateMask.setPathsList(updatePaths);
  }
  const reqBody = new UpdateDeviceRequest();
  reqBody.setDevice(deviceEdits);
  // @ts-ignore
  reqBody.setUpdateMask(updateMask);
  return client.updateDevice(reqBody, metadata);
};

export const getDeviceList = async (options: ListQueryOptions = {}) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const req = new SearchDevicesRequest();
  if (options.showDeleted) {
    req.setQuery(`state = DELETED OR state = ACTIVE`);
  }
  if (options.pageToken) {
    req.setPageToken(options.pageToken);
  }
  if (options.pageSize) {
    req.setPageSize(options.pageSize);
  }
  return client.searchDevices(req, metadata);
};

// Not implemented yet...
// export const getDeviceGroupList = async (orgName: string) => {
//     const metadata = await getMetadata([HermesPermissions.device])
//     const client = getDeviceRegistryClient();
//     return new Promise ((success, rej) => {
//         const req = new ListDeviceGroupsRequest();
//         req.setParent(orgName);
//         console.log({req})
//         client.listDeviceGroups(req, metadata, defaultResponseHandler(success, rej))
//     })
// }

export const getDeviceReport = async (deviceName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.deviceProgress]);
  const client = getDeviceRegistryPromiseClient();
  const req = new ReportProgressRequest();
  req.setName(deviceName);
  return client.reportProgress(req, metadata);
};

export enum DesiredState {
  DESIRED_STATE_UNSPECIFIED = 0,
  INSTALLED = 1,
  INSTALLED_AND_UPDATED = 2,
  REMOVED = 3,
}

export type InstallInstanceType = "version" | "channel" | "product";

export interface InstallType {
  type: InstallInstanceType;
  name: string;
}

export const sendDeviceCommand = async (
  deviceName: string,
  installType: InstallType,
  desiredState: DesiredState
) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const req = new SubmitCommandRequest();
  let command = new Command();
  let state = new Command.ProductState();

  if (installType.type === "version") {
    state.setVersion(installType.name);
  } else if (installType.type === "channel") {
    state.setChannel(installType.name);
  } else if (installType.type === "product") {
    state.setProduct(installType.name);
  }

  state.setDesiredState(desiredState);
  command.setProductStateCommand(state);
  req.setTarget(deviceName);
  req.setCommand(command);
  return client.submitCommand(req, metadata);
};

export const deleteDevice = async (deviceName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const req = new DeleteDeviceRequest();
  req.setName(deviceName);
  return client.deleteDevice(req, metadata);
};

export const undeleteDevice = async (deviceName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.device]);
  const client = getDeviceRegistryPromiseClient();
  const req = new UndeleteDeviceRequest();
  req.setName(deviceName);
  return client.undeleteDevice(req, metadata);
};

export const getPausedUntil = (device: Device) => {
  if (device.getAnnotationsMap().has(HermesAnnotationKeys.pause)) {
    let resumeDateString = device
      .getAnnotationsMap()
      .get(HermesAnnotationKeys.pause);
    if (resumeDateString) {
      return new Date(resumeDateString.slice(0, -1));
    }
  }
};

export const isPaused = (device: Device) => {
  if (device.getAnnotationsMap().has(HermesAnnotationKeys.pause)) {
    let resumeDate = new Date();
    let resumeDateString = device
      .getAnnotationsMap()
      .get(HermesAnnotationKeys.pause);

    if (resumeDateString) {
      resumeDate = new Date(resumeDateString.slice(0, -1));

      if (resumeDate < new Date()) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const unPause = async (device: Device) => {
  device.getAnnotationsMap().del(HermesAnnotationKeys.pause);
};

export const pause = async (device: Device, daysPaused: number) => {
  let pauseUntilDate = new Date();
  pauseUntilDate.setDate(pauseUntilDate.getDate() + Number(daysPaused));

  device
    .getAnnotationsMap()
    .set(HermesAnnotationKeys.pause, pauseUntilDate.toISOString());
};
