import "isomorphic-fetch";
import { Client } from "@microsoft/microsoft-graph-client";
import { defaultRequest, getAccessToken } from "@auth/auth";
import { SilentRequest } from "@azure/msal-browser";

/**
 *
 * @param {*} requestScopes Optional. List of scopes that should be included in the graph request(s)
 *
 * `{ scopes: ['directory.read.all'] }
 */
export const getGraphClient = (
  requestScopes: SilentRequest = defaultRequest
) => {
  return Client.initWithMiddleware({
    authProvider: {
      getAccessToken: () => getAccessToken(requestScopes),
    },
  });
};
