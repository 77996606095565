import * as React from "react";
import { Img, ImgProps } from "@chakra-ui/react";
import logo from "./logo.svg";
import { FC } from "react";

export interface LogoProps extends ImgProps {}

const Logo: FC<LogoProps> = ({ ...rest }) => {
  return <Img src={logo} {...rest} />;
};

export const SvgLogo = () => {
  return (
    // Set up proper SVG imports?
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <rect fill="#fbb040" width="1000" height="1000" rx="50" />
      <rect
        fill="#fff"
        x="169.78"
        y="169.78"
        width="660.44"
        height="660.44"
        rx="50"
        transform="translate(500 -207.11) rotate(45)"
      />
      <polygon
        fill="#414042"
        points="423.88 235 423.88 416.71 576.92 416.71 576.92 235 767.82 235 767.82 765 576.92 765 576.92 441.95 423.88 576 423.88 765 232.18 765 232.18 235 423.88 235"
      />
    </svg>
  );
};

export default Logo;
