import { ReactNode } from "react";
import { NavigationClient, NavigationOptions } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useHistory } from "react-router";
import { msalInstance } from "@auth/auth";
import { MsalEventHandler } from "@auth/MsalEventHandler";

/*
Tie the router history into msal so it can handle redirects to different routes
https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md#how-to-configure-azuremsal-react-to-use-your-routers-navigate-function-for-client-side-navigation
*/
class CustomNavigationClient extends NavigationClient {
  history: any;
  constructor(history: any) {
    super();
    this.history = history;
  }

  async navigateInternal(
    url: string,
    options: NavigationOptions
  ): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      this.history.replace(relativePath);
    } else {
      this.history.push(relativePath);
    }
    return false;
  }
}

export const MsalHistoryHandler = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const navClient = new CustomNavigationClient(history);
  msalInstance.setNavigationClient(navClient);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalEventHandler />
      {children}
    </MsalProvider>
  );
};
