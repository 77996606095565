import { defaultRequest, getTenantAuthority } from "@auth/auth";
import { Icon, ImageProps } from "@chakra-ui/react";
import { CleanImage } from "@components/CleanImage";
import { OrganizationalBranding } from "@microsoft/microsoft-graph-types";
import { getGraphClient } from "@utils/graph/graph";
import { VscAzure } from "react-icons/vsc";
import { useQuery, UseQueryOptions } from "react-query";

type ImageSizeOptions = keyof Pick<
  OrganizationalBranding,
  | "backgroundImageRelativeUrl"
  | "bannerLogoRelativeUrl"
  | "squareLogoRelativeUrl"
>;

export function useTenantBranding(
  tenantId: string,
  queryOptions: UseQueryOptions<OrganizationalBranding, unknown, unknown> = {}
) {
  return useQuery({
    queryKey: ["graph", "organization", tenantId, "branding"],
    queryFn: () =>
      getGraphClient({
        ...defaultRequest,
        // the organization endpoint needs a token issued for the
        // tenant we're requesting
        authority: getTenantAuthority(tenantId),
      })
        .api(`/organization/${tenantId}/branding`)
        .header("Accept-Language", "0")
        .get() as Promise<OrganizationalBranding>,
    enabled: !!tenantId,
    ...queryOptions,
  });
}

export interface TenantLogoProps extends ImageProps {
  tenantId: string;
  imgSize?: ImageSizeOptions;
}

export const TenantLogo = ({
  tenantId,
  imgSize = "squareLogoRelativeUrl",
  ...rest
}: TenantLogoProps) => {
  const { data: imageUrl } = useTenantBranding(tenantId, {
    select: (b) => {
      if (!b || !b.cdnList?.length) return "";
      const cdn = b.cdnList[0];
      return `https://${cdn}/${b[imgSize]}`;
    },
  });
  return (
    <CleanImage
      {...rest}
      src={imageUrl as string}
      fallback={
        <Icon
          as={VscAzure}
          color="blue.500"
          w={rest.w ?? rest.width ?? 4}
          h={rest.h ?? rest.height ?? 4}
        />
      }
    />
  );
};
