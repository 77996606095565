import { mode } from "@chakra-ui/theme-tools";

export const darkBg = `radial-gradient(61.04% 89.69% at 100% 100%, rgba(71, 74, 156, 0.08) 0%, rgba(110, 106, 8, 0.04) 40.63%, rgba(87, 88, 16, 0.05) 100%),
    radial-gradient(43.78% 64.34% at 60.31% 100%, rgba(23, 74, 228, 0.08) 0%, rgba(72, 53, 6, 0) 100%),
    linear-gradient(180deg, rgba(52, 93, 120, 0.03) 29.44%, rgba(23, 74, 228, 0.06) 100%),
    linear-gradient(90deg, #0e0e0e 0%, #1f2022 100%)`;

export const lightBg = `radial-gradient(61.04% 89.69% at 100% 100%, rgba(200, 250, 255, 0.08) 0%, rgba(19, 151, 167, 0.08) 40.63%, rgba(28, 210, 229, 0) 100%), 
    radial-gradient(43.78% 64.34% at 60.31% 100%, rgba(23, 74, 228, 0.08) 0%, rgba(23, 74, 228, 0) 100%), 
    linear-gradient(180deg, rgba(23, 74, 228, 0) 29.44%, rgba(23, 74, 228, 0.06) 100%), 
    linear-gradient(90deg, #F3F3F7 0%, #fffbf4 100%)`;

const styles = {
  // example of changing color for both light and dark modes
  // @ts-ignore
  global: (props) => ({
    body: {
      bg: mode(lightBg, darkBg)(props),
      color: mode("blackAlpha.800", "whiteAlpha.800")(props),
    },
    fontWeight: "thin",
    "--popper-bg": "neutral.800",
  }),
};

export default styles;
