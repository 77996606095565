import {
  Icon,
  IconProps,
  forwardRef,
  useColorModeValue,
} from "@chakra-ui/react";
import { VscPackage } from "react-icons/vsc";

export const VersionIcon = forwardRef<IconProps, "svg">((props, ref) => {
  const versionColor = useColorModeValue("purple.500", "purple.300");
  return (
    <Icon as={VscPackage} mr={1} ref={ref} color={versionColor} {...props} />
  );
});
