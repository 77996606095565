import { FC } from "react";
import { Image, ImageProps, Skeleton, useBoolean } from "@chakra-ui/react";

export interface CleanImageProps extends ImageProps {}

/**
 * Only shows the image when src is provided
 */
export const CleanImage: FC<CleanImageProps> = ({ ...props }) => {
  const { src, fallback, fallbackSrc } = props;
  const show = !!src || !!fallbackSrc || fallback ? true : false;
  // Image fallback renders on image load as well as image load error
  // We only want to show the fallback image if the original image didn't load
  // Image fallback renders on image load as well as image load error
  // We only want to show the fallback image if the original image didn't load
  const [ignoreFallback, setIgnoreFallback] = useBoolean(
    !fallbackSrc && !fallback
  );
  return (
    <Skeleton isLoaded={show}>
      <Image
        // Prevents flashing when image src is cleared
        opacity={!!src ? 1 : 0}
        {...props}
        ignoreFallback={ignoreFallback}
        onError={(err) => {
          setIgnoreFallback.off();
        }}
        alt="test"
      />
    </Skeleton>
  );
};
