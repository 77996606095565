// Gradients are applied as colors so they can be used in the `bg` or `backgroundColor` properties by name
export const darkBg = `radial-gradient(61.04% 89.69% at 100% 100%, rgb(71 74 56 / 13%) 0%, rgb(110 106 8 / 8%) 40.63%, rgb(87 88 16 / 0%) 100%), 
    radial-gradient(43.78% 64.34% at 60.31% 100%, rgba(23, 74, 228, 0.08) 0%, rgb(72 53 6 / 0%) 100%), 
    linear-gradient(180deg, rgb(112 93 20 / 6%) 29.44%, rgba(23, 74, 228, 0.06) 100%), 
    linear-gradient(90deg, #0e0e0e 0%, #1f2022 100%)`;

export const lightBg = `radial-gradient(61.04% 89.69% at 100% 100%, rgba(200, 250, 255, 0.08) 0%, rgba(19, 151, 167, 0.08) 40.63%, rgba(28, 210, 229, 0) 100%), 
    radial-gradient(43.78% 64.34% at 60.31% 100%, rgba(23, 74, 228, 0.08) 0%, rgba(23, 74, 228, 0) 100%), 
    linear-gradient(180deg, rgba(23, 74, 228, 0) 29.44%, rgba(23, 74, 228, 0.06) 100%), 
    linear-gradient(90deg, #F3F3F7 0%, #fffbf4 100%)`;

const colors = {
  bgGradient: lightBg,
  hermes: {
    50: "#fbb040",
    100: "#FFE7C2",
    200: "#FFD699",
    300: "#FFC670",
    400: "#FFB647",
    500: "#FFA51F",
    600: "#F59300",
    700: "#CC7A00",
    800: "#A36200",
    900: "#7A4900",
  },
  popper: {
    bg: "neutral.800",
  },
  neutral: {
    20: "rgb(51, 51, 51)",
    50: "rgb(76, 76, 76)",
    100: "rgb(102, 102, 102)",
    200: "rgb(166, 166, 166)",
    300: "rgb(200, 200, 200)",
    400: "rgb(218, 218, 218)",
    500: "rgb(234, 234, 234)",
    600: "rgb(239, 239, 239)",
    700: "rgb(244, 244, 244)",
    800: "rgb(248, 248, 248)",
    900: "rgb(255, 255, 255)",
  },
  modes: {
    dark: {
      bgGradient: darkBg,
      gray: {
        50: "rgb(190, 187, 184)",
        100: "rgb(161, 159, 157)",
        200: "rgb(121, 119, 117)",
        300: "rgb(96, 94, 92)",
        400: "rgb(72, 70, 68)",
        500: "rgb(59, 58, 57)",
        600: "rgb(50, 49, 48)",
        700: "rgb(41, 40, 39)",
        800: "rgb(37, 36, 35)",
        900: "rgb(32, 31, 30)",
      },
      neutral: {
        50: "rgb(190, 187, 184)",
        100: "rgb(161, 159, 157)",
        200: "rgb(121, 119, 117)",
        300: "rgb(96, 94, 92)",
        400: "rgb(72, 70, 68)",
        500: "rgb(59, 58, 57)",
        600: "rgb(50, 49, 48)",
        700: "rgb(41, 40, 39)",
        800: "rgb(37, 36, 35)",
        900: "rgb(32, 31, 30)",
      },
    },
    light: {
      gray: {
        50: "rgb(248, 249, 249)",
        100: "rgb(231, 234, 236)",
        200: "rgb(211, 216, 219)",
        300: "rgb(181, 189, 195)",
        400: "rgb(161, 171, 178)",
        500: "rgb(146, 157, 166)",
        600: "rgb(91, 102, 111)",
        700: "rgb(78, 88, 96)",
        800: "rgb(66, 74, 81)",
        900: "rgb(41, 46, 50)",
      },
    },
  },
};

export default colors;
