const Textarea = {
  variants: {
    filled: {
      bg: "neutral.800",
      _hover: {
        bg: "neutral.700",
      },
    },
  },
};

export default Textarea;
