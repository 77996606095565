import { getHermesEnvironment } from "@env/envrionment";

// Note that the Hermes API doesn't actually care about this value since it
// strips the base, but Azure needs this to find the right API permission
// for the current environment.
const permissionsBase = getHermesEnvironment().hermesApi.appIdUri;

/**
 * Api Permissions are used in requests to the Hermes service.
 */
export const HermesApiPermissions = {
  operation: `${permissionsBase}Operations` as const,
  operationRead: `${permissionsBase}Operations.Read` as const,
  device: `${permissionsBase}Device` as const,
  deviceRead: `${permissionsBase}Device.Read` as const,
  deviceProgress: `${permissionsBase}Device.Progress` as const,
  directory: `${permissionsBase}Directory` as const,
  directoryRead: `${permissionsBase}Directory.Read` as const,
  chunkStore: `${permissionsBase}ChunkStore` as const,
  chunkStoreRead: `${permissionsBase}ChunkStore.Read` as const,
  chunkStorePull: `${permissionsBase}ChunkStore.Pull` as const,
  iam: `${permissionsBase}IAM` as const,
  iamRead: `${permissionsBase}IAM.Read` as const,
  product: `${permissionsBase}Product` as const,
  productRead: `${permissionsBase}Product.Read` as const,
};
