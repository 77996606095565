import { StyleObjectOrFn } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const hoverable: StyleObjectOrFn = (props) => {
  const hoverBg = mode("whiteAlpha.800", "whiteAlpha.100")(props);
  return {
    borderColor: "neutral.200",
    borderRadius: 4,
    _hover: { boxShadow: "md", bg: hoverBg },
    _focusWithin: { boxShadow: "outline" },
  };
};

const selectable: StyleObjectOrFn = (props) => {
  const { selected } = props;
  const selectedColor = mode("whiteAlpha.300", "whiteAlpha.300")(props);
  return {
    ...hoverable(props),
    bg: !!selected ? selectedColor : "",
    cursor: "pointer",
    tabIndex: 0,
  };
};

const card: StyleObjectOrFn = (props) => {
  const bgColor = mode("whiteAlpha.800", "whiteAlpha.50")(props);
  const boxShadow = mode("md", "lg")(props);
  return {
    borderRadius: "md",
    backgroundColor: bgColor,
    boxShadow,
  };
};

const hoverCard: StyleObjectOrFn = (props) => {
  const bgColor = mode("whiteAlpha.800", "whiteAlpha.50")(props);
  const hoverBg = mode("whiteAlpha.600", "whiteAlpha.100")(props);

  const boxShadow = mode("md", "lg")(props);
  const hoverBoxShadow = mode("lg", "xl")(props);

  return {
    borderRadius: "md",
    backgroundColor: bgColor,
    boxShadow,
    _hover: { bgColor: hoverBg, boxShadow: hoverBoxShadow },
    _focusWithin: { bgColor: hoverBg },
    transition: `box-shadow 0.1s ease-in-out`,
  };
};

const CustomBox = {
  variants: {
    card,
    hoverCard,
    hoverable,
    selectable,
  },
};

export default CustomBox;
