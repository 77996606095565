import { msalInstance } from "@auth/auth";
import { AccountInfo } from "@azure/msal-browser";
import { create } from "zustand";

interface TenantState {
  account: AccountInfo | null;
  setAccount(account: AccountInfo): void;
}

/**
 * Global state to select an acount other than the active account in MSAL.
 * Useful when explicitly making requests to other tenants (ex. MS Graph)
 */
const useAccountStore = create<TenantState>()((set) => ({
  account: msalInstance.getActiveAccount(),
  setAccount: (a) => set((state) => ({ ...state, account: a })),
}));

export default useAccountStore;
