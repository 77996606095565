// eslint-disable-next-line
import { TestIamPermissionsRequest } from "@xboxstudios/hermes-apis/xboxstudios/hermes/iam/v1/iam_policy_pb";

// These permissions are defined in the Hermes service source code
const makePerms = (resourceType: string) => {
  return {
    Get: `${resourceType}.${resourceType}s.get`,
    List: `${resourceType}.${resourceType}s.list`,
    Update: `${resourceType}.${resourceType}s.update`,
    Delete: `${resourceType}.${resourceType}s.delete`,
    Undelete: `${resourceType}.${resourceType}s.undelete`,
    GetIamPolicy: `${resourceType}.${resourceType}s.getIamPolicy`,
    SetIamPolicy: `${resourceType}.${resourceType}s.setIamPolicy`,
  };
};

export type Permissions = ReturnType<typeof makePerms>;

/** Permissions are used to check a user's ability to
 * perform a specific action via {@link TestIamPermissionsRequest}
 */
export const HermesPermissions = {
  Device: makePerms("device"),
  Product: makePerms("product"),
  ChunkStore: makePerms("chunkstore"),
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HermesPermission = typeof HermesPermissions;
