import {
  UseToastOptions,
  useToast as useChakraToast,
  useColorMode,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertIconProps,
  AlertProps,
  AlertTitle,
  Button,
  ButtonProps,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import { FC } from "react";
import { VscDiscard } from "react-icons/vsc";

interface BaseCustomToastOptions extends UseToastOptions {}

interface UndoCustomToastOptions
  extends Omit<BaseCustomToastOptions, "status"> {
  status: "undo";
  undoProps: UserUndoToastProps;
}

type CustomToastOptions = BaseCustomToastOptions | UndoCustomToastOptions;

/**
 * Unfortuantely chakra doesn't provide theme overrides for the toast component,
 * and style overrides on the Alert component (which is used by the toast) are not
 * working as expected. This hook is a workaround to provide a consistent toast
 * component across the app as well as simplify the use of more complex toasts
 * like undo toasts.
 */
export const useCustomToast = () => {
  const toast = useChakraToast();
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";

  function customToastFn() {
    const ctf = ({
      status,
      position = "bottom-right",
      variant = "left-accent",
      isClosable = true,
      containerStyle = {},
      // @ts-ignore
      undoProps,
      ...props
    }: CustomToastOptions) => {
      // Render custom toasts here...
      if (!props.render) {
        if (status === "undo") {
          props.render = ({ id, onClose }) => (
            <UndoToast
              id={id}
              heading={props.title || "Success"}
              message={props.description || ""}
              onClose={onClose}
              variant={variant}
              {...undoProps}
            />
          );
        }
      }

      return toast({
        position: "bottom-right",
        variant,
        isClosable,
        // @ts-ignore
        status,
        containerStyle: {
          // The default bg on the alert component in dark mode is pretty transparent and
          // hard to see, so we set a darker bg color here to make it more visible.
          backgroundColor: isDark ? "var(--chakra-colors-blackAlpha-900)" : "",
          borderRadius: isDark ? "var(--chakra-radii-md)" : "",
          ...containerStyle,
        },
        ...props,
      });
    };

    // The toast function returned by useToast has a few methods that need to
    // expose on our custom toast function
    const { close, closeAll, update, isActive } = toast;
    ctf.close = close;
    ctf.closeAll = closeAll;
    ctf.update = update;
    ctf.isActive = isActive;

    return ctf;
  }

  return customToastFn();
};

interface UserUndoToastProps extends Omit<AlertProps, "id"> {
  onUndo(): Promise<unknown>;
  iconProps?: AlertIconProps;
  buttonProps?: ButtonProps;
}

interface UndoToastProps extends UserUndoToastProps {
  id: UseToastOptions["id"];
  heading: UseToastOptions["title"];
  message: UseToastOptions["description"];
  onClose(): void;
}

const UndoToast: FC<UndoToastProps> = ({
  id,
  heading,
  message,
  onUndo,
  onClose,
  iconProps = {},
  buttonProps = {},
  ...rest
}) => {
  return (
    <Alert
      id={id?.toString()}
      position="relative"
      status="success"
      bgColor="neutral.700"
      {...rest}
    >
      <CloseButton
        onClick={onClose}
        position="absolute"
        size="sm"
        top={1}
        right={1}
      />
      <AlertIcon {...iconProps} />
      <Flex direction="column" gap={2} flex="1">
        <AlertTitle>{heading}</AlertTitle>
        <AlertDescription minH={4}>{message}</AlertDescription>
      </Flex>
      <Flex
        direction="column"
        justifyContent="flex-end"
        alignSelf="stretch"
        gap={2}
      >
        <Button
          size="xs"
          leftIcon={<VscDiscard />}
          alignSelf="flex-end"
          {...buttonProps}
          onClick={async () => {
            await onUndo();
            onClose();
          }}
        >
          Undo
        </Button>
      </Flex>
    </Alert>
  );
};
