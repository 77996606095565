import { defaultRequest, getAccessToken } from "@auth/auth";
import { getHermesEnvironment } from "@env/envrionment";
import { IAppInsights } from "@microsoft/applicationinsights-web";
import { HermesApiPermissions } from "@utils/hermes-api/api-permissions";

/**
 * The service worker needs to be able to request access tokens
 * to authenticate certain requests (like <img/> requests). Since these
 * requests will require different scopes we need to let the SW decide
 * which scope(s) to request. Communication is done via the client message
 * API since it allows communication between code in different threads
 * of a single origin (i.e. the web app and its service worker).
 */

export type SWTokenRequestScopeType = "product" | "device" | "operation" | "";

function handleSWTokenRequest(scopeType: SWTokenRequestScopeType) {
  if (scopeType && scopeType in HermesApiPermissions) {
    return getAccessToken({ scopes: [HermesApiPermissions[scopeType]] });
  }

  return getAccessToken(defaultRequest);
}

export function registerSWMessageTokenHandler(logger: IAppInsights) {
  if ("serviceWorker" in navigator) {
    // epatt: disabling SW reload since it's causing infinite page reload
    // loop on hard refresh or for users that don't have the SW registered
    // before visiting page.

    // This will reload the page if the controller value is null after hard-reload
    // So service worker will be registered correctly
    //This code was added due to image loading issue found in BUG 47605
    //   if (navigator.serviceWorker.controller === null) {
    //     window.location.reload();
    //   }
    return navigator.serviceWorker.ready.then((worker) => {
      navigator.serviceWorker.onmessage = (e: MessageEvent) => {
        const scopeType = e.data;
        // only listen for scope requests
        if (typeof e.data === "string") {
          handleSWTokenRequest(scopeType).then((token) => {
            navigator.serviceWorker.controller?.postMessage({
              scopeType,
              token,
            });
          });
        }
      };
    });
  } else {
    return Promise.reject(`Could not register service worker message handler.
    The service worker will not be able to receive access tokens.`);
  }
}

// Notify the service worker which API endpoint we're using
// so it can identify the correct requests to attach Hermes
// bearer tokens to
window.onload = () => {
  setSWApiEndpoint(getHermesEnvironment().hermesApi.endpoint);
};

function setSWApiEndpoint(apiEndpoint: string) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.controller?.postMessage({
      apiEndpoint,
    });
  }
}
