import { FC } from "react";
import { Avatar, AvatarProps, Fade } from "@chakra-ui/react";
import { useUserPhotoQuery } from "@context/http-cache/graph-queries/user";

const stringToHslColor = (text: any, saturation = 50, lightness = 70) => {
  var h = 0;
  if (text) {
    for (var i = 0; i < text.length; i++) {
      h = text.charCodeAt(i) + ((h << 5) - h);
    }
    h = h % 360;
  } else {
    return "hsl(0, 0%, " + lightness + "%)";
  }
  return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
};

export interface PersonaProps extends AvatarProps {
  imageDimensions?: string;
  imageUrl?: string;
  imageInitials?: string;
  initialsColor?: string;
  text?: string;
  userId: string;
  email?: string;
  tenantId: string;
}

export const Persona: FC<PersonaProps> = ({
  imageDimensions,
  imageUrl,
  imageInitials,
  initialsColor,
  text,
  userId,
  tenantId,
  ...rest
}) => {
  const backgroundColor = initialsColor || stringToHslColor(text);

  const { data: imgSrc, isFetched } = useUserPhotoQuery(
    tenantId,
    userId,
    imageDimensions,
    {
      enabled: !!tenantId && !!userId,
    }
  );

  return (
    <Fade in={isFetched}>
      <Avatar
        className="avatar"
        name={text}
        backgroundColor={backgroundColor}
        src={imgSrc}
        boxShadow="base"
        loading="lazy"
        {...rest}
      ></Avatar>
    </Fade>
  );
};

export default Persona;
